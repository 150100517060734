<template>
  <v-app>
    <Snackbar :snackBarComponent="snackBarComponent" @update:snackBarComponent="updateSnackbar" />
    <v-card tile :height="innnerHeight" flat class="CardDesign">
      <v-row no-gutters>
        <v-col cols="12" class="right-column">
          <v-card class="elevation-0 pa-4 blur-bg" color="transparent" width="350px">
            <div class="container text-left d-flex align-start">
              <v-img cla src="../../assets/logo-dark@2x.png" alt="logo" height="60" class="ml-n10" />
            </div>
            <h2 class="mt-7 mb-3 text-left">BMS - Login</h2>
            <v-form ref="LoginForm" class="mt-2">
              <v-row>
                <v-col cols="12">
                  <div class="text-left FontSize18px font-weight-bold">Email</div>
                  <v-text-field variant="outlined" density="compact" class="mt-2" type="email"
                    placeholder="Enter Email Id" autocomplete="on" :filled="IsOTPFieldEnabled"
                    @keypress.enter="validateMethod('SEND_OTP')" v-model.trim="Login.email" :rules="[
                      (v) => !!v || 'This field is required',
                      (v) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || 'E-mail must be valid',
                    ]"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <div class="FontSize12px text-left" v-if="IsOTPFieldEnabled">Please enter the OTP that was sent to your
              registered email:</div>
            <v-otp-input :length="6" v-show="IsOTPFieldEnabled" ref="autoFocusOtpField" class="FontSize12px"
              v-model="Login.otp" type="number" :rules="[(v) => !!v || 'required']"></v-otp-input>
            <div class="text-left">
              <v-btn v-if="IsOTPFieldEnabled && counter === 0" small variant="text" depressed block dark color="primary"
                :disabled="counter !== 0" class="text-capitalize pa-0 ma-0 font-weight-bold"
                @click="signInMethod('SEND_OTP')">Resend OTP</v-btn>
              <span class="grey--text FontSize12px" v-if="IsOTPFieldEnabled == true && counter !== 0">Resend OTP
                available in {{ counter }}</span>
            </div>
            <v-card-actions class="pa-0">
              <v-btn color="primary" class="text-capitalize" :loading="LoginLoader" variant="flat" dark block
                @click="validateMethod(IsOTPFieldEnabled ? 'VERIFY_OTP' : 'SEND_OTP')">{{ IsOTPFieldEnabled ?
                  "VerifyOTP" : "Get OTP" }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-app>
</template>

<script>
import { signIn, confirmSignIn, signOut } from "aws-amplify/auth";
import Snackbar from "@/components/Extras/SnackbarComp.vue";
//import axios from "axios";
export default {
  components: {
    Snackbar,
  },
  data: () => ({
    IsOTPFieldEnabled: false,
    LoginLoader: false,
    IsSessionLoggedOut: false,

    counter: 0,
    innnerHeight: 0,
    interval: null,
    SuccessMessage: "",

    snackBarComponent: {},
    Login: { email_id: "", otp: "" },
    user: null,
  }),
  mounted() {
    this.innnerHeight = window.innerHeight;
    this.disableBrowserRoutingChanges();
    if (localStorage.getItem("IsLoggedOut") === "true") {
      this.IsSessionLoggedOut = true;
    } else {
      this.IsSessionLoggedOut = false;
    }
  },
  methods: {
    async validateMethod(action) {
      const isValid = await this.$refs.LoginForm.validate();

      if (isValid.valid) {
        this.signInMethod(action);
      } else {
        this.snackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Please enter Email to Login",
        };
      }
    },

    async signInMethod(action) {
      switch (action) {
        case "SEND_OTP":
          try {
            this.LoginLoader = true;
            this.user = await signIn({
              username: this.Login.email,
              options: {
                authFlowType: "CUSTOM_WITHOUT_SRP",
              },
            });
            if (this.user.nextStep.signInStep == "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE") {
              this.snackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                timeout: 2000,
                SnackbarText: `Verification OTP sent to ${this.Login.email}`,
              };

              this.counter = 60;
              this.LoginLoader = false;
              this.$refs.autoFocusOtpField.reset();
              setTimeout(() => {
                this.IsOTPFieldEnabled = true;
                setTimeout(() => {
                  this.$refs.autoFocusOtpField.focus();
                }, 100);
              }, 1000);
              this.$forceUpdate();
              this.startCounter();
            }
          } catch (error) {
            console.log("errrrr", error);
            if (error.message == "There is already a signed in user.") {
              signOut({
                global: true,
              });
            } else {
              console.log("error", error);
              if (error.message === "DefineAuthChallenge failed with error NOT_AUTHORIZED : Kindly Sigup.") {
                this.LoginLoader = false;
                this.snackBarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "red",
                  SnackbarText: "User not found, Kindly Sign Up",
                };
              }
              // setTimeout(() => {
              //   this.$router.push("/registration");
              // }, 2000);
            }
            this.LoginLoader = false;
          }
          break;
        case "VERIFY_OTP":
          try {
            this.LoginLoader = true;
            await confirmSignIn({ challengeResponse: this.Login.otp })
              .then((data) => {
                this.LoginLoader = false;
                this.snackBarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "green",
                  SnackbarText: "Login Successfull",
                };
                this.IsOTPFieldEnabled = false;
                this.IsSessionLoggedOut = false;
                localStorage.setItem("email", this.Login.email);
                this.activateMethod();
              })
              .catch((err) => {
                if (err) {
                  this.counter = 0;
                  this.Login.otp = "";
                }
                this.snackBarComponent.SnackbarVmodel = false;
                this.snackBarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "red",
                  SnackbarText: err.message,
                };
                this.OTPErrorMessage = err.message;
                this.LoginLoader = false;
              });
          } catch (error) {
            this.LoginLoader = false;
          }
          break;
      }
    },
    disableBrowserRoutingChanges() {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
      };
    },
    startCounter() {
      this.interval = setInterval(() => {
        if (this.counter > 0) {
          this.counter--;
        } else {
          clearInterval(this.interval);
        }
      }, 1000);
    },
    beforeDestroy() {
      clearInterval(this.interval);
    },
    activateMethod() {
      const redirectRoute = this.$route.query.redirect;

      if (redirectRoute) {
        // Redirect back to the original page
        setTimeout(() => {
          this.$router.push(redirectRoute);
        }, 1000);
      } else {
        // Default to landing page if no redirect route exists
        setTimeout(() => {
          this.$router.push("/landingpage");
        }, 1000);
      }
    },
    updateSnackbar(newSnackbarData) {
      this.snackBarComponent = newSnackbarData;
    },
  },
};
</script>

<style>
.CardDesign {
  /* background: url("~@/assets/bg1.jpg") !important; */
  background-size: cover !important;

  background: #f8f9fa;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100dvh !important;
}

.heading {
  text-align: center;
  backdrop-filter: blur(10px);
  font-size: 3rem;
}

.heading-text {
  color: #0f5af5;
  backdrop-filter: blur(10px);
  background: linear-gradient(to right, #0f5af5, #8dc6ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3.5rem;
  letter-spacing: 1.5px;
}

.right-column {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d0fbe3;
  /* background-image: linear-gradient(to left, #ffe988 0%, #50cc7f 100%);
   */
  /* background-image: url(https://t4.ftcdn.net/jpg/03/60/29/63/360_F_360296349_2pm4h5a8NeCIVnBgFZip8Pt7WbEXdYeV.jpg);
  object-fit: cover; */
}

/* .wrapper {       nn
        width: 450px;
        max-height: 450px;
      
        border-radius: 8px;
        padding: 30px;
        text-align: center;
        right: 150px !important;
        box-shadow: 20px 20px 10px !important;
      
        border: 1px solid rgba(255, 255, 255, 0.767);
        backdrop-filter: blur(10px);
        position: absolute !important;
        -webkit-backdrop-filter: blur(10px);
      } */
.blur-bg {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.416);
}
</style>
