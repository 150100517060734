<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<style>
.CustomizedTableClass table {
  border: 1px solid #f2f2f2 !important;
  border-collapse: collapse !important;
  /* border-radius: 10px !important; */
  overflow: hidden !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important;
  margin: 0 auto !important;
}
.CustomizedTableClass table tr:nth-child(even) {
  background-color: #e5f6f0 !important;
}
.CustomizedTableClass table th {
  position: -webkit-sticky !important;
  top: 0 !important;
  z-index: 0 !important;
  background: #01ab6e !important;
  color: #fff !important;
}
</style>
