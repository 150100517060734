<template>

  <v-snackbar v-model="snackBarComponent.SnackbarVmodel" :color="snackBarComponent.SnackbarColor" location="top"
    class="snackbarClass" :timeout="snackBarComponent.timeout" style="text-align: center" variant="flat">

    <div class="d-flex justify-center">

      {{ snackBarComponent.SnackbarText }}

    </div>

  </v-snackbar>

</template>



<script>

export default {

  props: {

    snackBarComponent: Object,

  },

};

</script>



<style scoped>
.snackbarClass {

  text-align: center !important;

  font-weight: bold !important;

}
</style>