import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

export default createStore({
  state: {
    current_user: null,
  },
  getters: {
    get_current_user_details(state) {
      return state.current_user;
    },
  },
  mutations: {
    SET_CUURENT_USER(state, payload) {
      return (state.current_user = payload);
    },
  },
  plugins: [new VuexPersistence().plugin],
});
