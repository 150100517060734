// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { VDateInput } from "vuetify/labs/VDateInput";
import { VTimePicker } from "vuetify/labs/VTimePicker";
import { VNumberInput } from "vuetify/labs/VNumberInput";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  components: {
    VDateInput,
    VTimePicker,
    VNumberInput,
  },
  theme: {
    defaultTheme: "light",

    themes: {
      light: {
        colors: {
          primary: "#00ab6e",
          secondary: "#6aebb2",
          accent: "#ebfef4",
          navDrawerColor: "#eef7ff",
          buttonColor: "#e84b4b",
          pageBackground: "#F6F5F2",
          pageBackgroundVariant: "#21262c",
          lightGreenColor: "#dff6e1",
          greyColorVariant1: "#cccccc",
          greyColorVariant2: "#333333",

          greenColorVariant1: "#cce5cc",
          greenColorVariant2: "#329932",

          redColorVariant1: "#ffb2b2",
          redColorVariant2: "#ff1919",

          blueColorVariant1: "#c7dcf6",
          blueColorVariant2: "#304b9d",
        },
      },
    },
  },
});
