// src/router/index.js

import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "../views/MainLandingPages/LoginPage.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginPage,
    meta: {
      title: "Call Central BMS | Login",
    },
  },
  {
    path: "/landingpage",
    name: "landingpage",

    component: () => import("@/views/MainLandingPages/LandingPage.vue"),
    meta: {
      title: "Landing Page",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",

        component: () => import("@/views/ChildHomePages/DashboardPage.vue"),
        meta: {
          title: "Dashboard",
        },
      },

      {
        path: "/bms-users",
        name: "bms-users",

        component: () => import("@/views/ChildHomePages/BmsUsers.vue"),
        meta: {
          title: "BMS Users",
        },
      },
      {
        path: "/callagents",
        name: "callagents",

        component: () => import("@/views/ChildHomePages/CallCentralAgents.vue"),
        meta: {
          title: "Call Agents",
        },
      },

      {
        path: "/organizations",
        name: "organizations",

        component: () => import("@/views/ChildHomePages/OrganizationsPage.vue"),
        meta: {
          title: "Organizations",
        },
      },
      {
        path: "/organizations-approval",
        name: "organizations-approval",

        component: () => import("@/views/ChildHomePages/OrgnizationApprovals.vue"),
        meta: {
          title: "Approvals",
        },
      },

      {
        path: "/disposition-codes",
        name: "disposition-codes",

        component: () => import("@/views/ChildHomePages/DispositionCodesPage.vue"),
        meta: {
          title: "Disposition Codes",
        },
      },

      {
        path: "/languages",
        name: "languages",

        component: () => import("@/views/ChildHomePages/LanguagesPage.vue"),
        meta: {
          title: "Languages",
        },
      },

      {
        path: "/industries",
        name: "industries",

        component: () => import("@/views/ChildHomePages/IndustriesPage.vue"),
        meta: {
          title: "Industries",
        },
      },

      {
        path: "/product-categories",
        name: "product-categories",

        component: () => import("@/views/ChildHomePages/ProductCategoriesPage.vue"),
        meta: {
          title: "Product Categories",
        },
      },

      {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/ChildHomePages/Settings.vue"),
        meta: {
          title: "Settings",
        },
        children: [
          {
            path: "regions",
            name: "regions",
            component: () => import("@/views/ChildHomePages/RegionsPage.vue"),
            meta: {
              title: "Regions",
            },
          },
          {
            path: "sipproviders",
            name: "sipproviders",
            component: () => import("@/components/SIPProviders/Cards/SipProvidersCard.vue"),
            meta: {
              title: "SIP Providers",
            },
            children: [
              {
                path: "callerids",
                name: "callerids",
                component: () => import("@/components/CallerIds/Cards/CallerIds.vue"),
                meta: {
                  title: "Caller Ids",
                },
              },
            ],
          },
          {
            path: "general-settings",
            name: "general-settings",
            component: () => import("@/components/Credits/Cards/CreditListCard.vue"),
            meta: {
              title: "General Settings",
            },
          },
        ],
      },
    ],
  },

  // Add other routes here
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  const defaultTitle = "Call Centrals | BMS"; // Set a default title if meta.title is not defined
  document.title = to.meta.title || defaultTitle;
  next();
});

export default router;
